import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';

import Analytics from 'components/dashboards/analytics';

const FalconRoutes = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        {/*Dashboard*/}
        <Route path="/" element={<Analytics />} />
      </Route>

      {/* //--- MainLayout end  */}

      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CardDropdown from 'components/common/CardDropdown';
import IconButton from 'components/common/IconButton';
import LeafletMap from './LeafletMap';

const ProjectLocation = ({ data }) => {
  return (
    <LeafletMap
      data={data}
      className="h-100 bg-white"
      style={{ minHeight: '300px' }}
    />
  );
};

ProjectLocation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
};

export default ProjectLocation;
